<template>
  <div>
    <dashboard-page-title v-if="productItem.length < 0" :showSearch="false" :showFilter="false" :showMainActions="false" @addEvent="$router.push({name: 'addMerchant'})">
    إضافة منتج
    </dashboard-page-title>
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> معلومات أساسية</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <input-form placeholder="أدخل الاسم" label="الإسم" v-model="product.name" name="product name" :disabled="routeName === 'showProduct'" validate="required"></input-form>
                  </b-col>
                  <b-col md="12" class="mb-3">
                    <textarea-form placeholder="الوصف" v-model="product.description" label="وصف مختصر" name="details" :disabled="routeName === 'showProduct'" validate="required"></textarea-form>
                  </b-col>
                  <b-col md="12" class="mb-3">
                    <textarea-form  placeholder="المحتوى" v-model="product.content" label="التفاصيل"  name="description" :disabled="routeName === 'showProduct'" validate="required"></textarea-form>
                  </b-col>
                </b-row>
            </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i>  الصور</h5>
                </template>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <cropper-images
                        label="رفع الصورة الرئيسية"
                        nameOfImage="image.jpg"
                        @cropper-save="saveMasterImage"
                        :progressLoading="loadingLogo"
                        :showProgress="false"
                        :multi="false"
                        :imageUrl="product.featured_image"
                    />
                  </b-col>
                  <b-col md="12" class="mb-3">
<!--                    <div class="d-flex align-items-center">-->
<!--                      <div v-for="(arrImg, key) in product.images" :key="key" class="view-main-img">-->
<!--                        <div class="image_slider_background d-flex align-items-center"-->
<!--                             :style="{-->
<!--                    'background-image': 'url(' + arrImg.image + ')',-->
<!--                      'background-size' : 'cover',-->
<!--                      'background-position': 'center'-->
<!--                  }"-->
<!--                        ></div>-->
<!--                      </div>-->
<!--                    </div>-->
                    <cropper-images
                        label="رفع الصور المتبقية"
                        @cropper-save="saveGalleryImage"
                        @remove-image="removeGalleryImage"
                        :progressLoading="loadingGallery"
                        :removeLoadingUi="removeLoadingUi"
                        :images="product.images"
                    ></cropper-images>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-info"></i>  تفاصيل المنتج</h5>
                </template>
                <b-row>
                  <b-col md="4" class="mb-3">
                    <input-form :placeholder="product.price" label="السعر" v-model="product.price" name="price" :validate="'required|numeric'" :disabled="routeName === 'showProduct'"></input-form>
                  </b-col>
<!--                  <b-col md="4" class="mb-3">-->
<!--                    <input-form placeholder=" 0" label="الخصم" v-model="product.discounts" name="discounts" validate="numeric" :disabled="routeName === 'showProduct'"></input-form>-->
<!--                  </b-col>-->
                  <b-col md="4" class="mb-3">
                    <input-form :placeholder="product.quantity" label="الكمية" v-model="product.quantity" name="quantity" :validate="'required|numeric'" :disabled="routeName === 'showProduct'"></input-form>
                  </b-col>
                  <b-col md="4" class="mb-3">
                    <input-form placeholder="SKU" label="SKU" v-model="product.sku" name="SKU"  :disabled="routeName === 'showProduct'"></input-form>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-truck"></i>  الشحن</h5>
                </template>
                <b-row>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="مثال : 1000 جم " label="الوزن" v-model="product.weight" name="size" validate="numeric"></input-form>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="مثال : 1000 سم" label="الطول" v-model="product.height" name="tall" validate="numeric"></input-form>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="مثال : 200 سم" label="العرض" v-model="product.width" name="width" validate="numeric"></input-form>
                  </b-col>
                  <b-col md="6" class="mb-3">
                    <input-form placeholder="مثال : 200 سم" label="الارتفاع" v-model="product.length" name="hight" validate="numeric"></input-form>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-link"></i>
                    منتجات ذات صلة
                  </h5>
                 </template>
                <b-row>
                  <b-col md="12" class="mb-3">
                    <main-select label-title= 'المنتجات' multiple :options="allProducts" dir="rtl"
                                 v-model="selectedProduct" label="name"
                                 :selectable="option => !selectedProduct.includes(option.id)"
                                 :reduce="product => product.id" @deselected="removeProduct" @selecting="addProduct"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-primary">
                      <i class="las la-question-circle"></i>
                      الاسئلة الشائعة الخاصة بالمنتج
                    </h5>
                    <span class="cursor-pointer text-warning font-size-16" v-if="!showFaqInput" @click="showFaqInput = true"> إضافة سؤال +</span>
                    <span class="cursor-pointer text-warning font-size-16" v-else @click="showFaqInput = false"> إلغاء السؤال</span>
                  </div>
                </template>
                <ValidationObserver v-slot="{ handleSubmit }" ref="faqs" v-if="showFaqInput">
                  <b-form @submit.prevent="handleSubmit(addFaq)">
                    <b-row>
                      <b-col md="12" >
                        <input-form placeholder="السؤال" label="السؤال" v-model="faq.question" name="السؤال" validate="required"></input-form>
                      </b-col>
                      <b-col md="12">
                        <input-form placeholder="الإجابة" label="الإجابة" v-model="faq.answer" name="الإجابة" validate="required"></input-form>
                      </b-col>
                      <b-col class="justify-content-end d-flex my-2">
                        <b-button variant="warning"  type="submit" class="text-white w-20"> حفظ السؤال </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </ValidationObserver>
                <b-row>
                  <b-col md="12" class="mb-3 cursor-pointer" v-if="product.faqs.length == 0">
                    <p class="mb-0 font-size-18 mt-2">لا يوجد أسئلة</p>
                  </b-col>
                  <b-col md="12" v-else class="d-flex justify-content-between align-items-center mb-4" v-for="(faq, key) in product.faqs" :key="key">
                    <div class="flex-1">
                    <p>{{key + 1}} - {{ faq.question }}</p>
                    <p class="px-1">{{ faq.answer }}</p>
                    </div>
                     <span  @click="removeFaq(key)" class="cursor-pointer">
                        <i class="ri-delete-bin-line text-danger font-size-20" />
                     </span>
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <div class="d-flex justify-content-between align-items-center">
                    <h5 class="text-primary">
                      <i class="las la-file-word"></i>
                      كلمات دالة
                    </h5>
                  </div>
                </template>
                <b-row>
                  <b-col md="12" class="mb-3 cursor-pointer">
                    <main-select  :multiple="true" taggable v-model="product.tags" label-title="ادخل الكلمات"></main-select>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card v-if="$route.name !== 'showProduct'" class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-save"></i>
                    النشر
                  </h5>
              </template>
              <div class="d-flex justify-content-end gap_1" v-if="!loadingSubmit">
                <b-button variant="primary" class="w-50" type="submit" @click="product.status = 'published'"> حفظ و نشر </b-button>
                <b-button variant="warning" class="text-white w-50" type="submit" @click="product.status = 'pending'"> حفظ </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                <b-button variant="warning" class="text-white w-100" disabled type="submit"> <spinner-loading text="loading"></spinner-loading> </b-button>
              </div>
            </b-card>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-exclamation"></i>
                    الحالة
                  </h5>
              </template>
              <div>
               <v-select placeholder="اختر الحالة" v-model="product.status" :dir="'rtl'"  :options="allStatus"></v-select>
              </div>
            </b-card>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-box-open"></i>
                    الأقسام
                  </h5>
              </template>
              <div class="m-0 p-0">
                <p v-for="(i,key) in allCategories" :key="key">
                  <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="product.product_categories" color="warning"
                                   name="color" :value="i.id" >
                    <span class="text-black font-size-16 mx-4">{{i.name}}</span>
                  </b-form-checkbox>
                </p>
              </div>
            </b-card>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                  <h5 class="text-primary">
                    <i class="las la-layer-group"></i>
                    المجموعات
                  </h5>
              </template>
              <div class="m-0 p-0">
                <p v-for="(i,key) in allCollections" :key="key">
                  <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="product.product_collections" color="warning"
                                   name="color" :value="i.id" >
                    <span class="text-black font-size-16 mx-4">{{i.name}}</span>
                  </b-form-checkbox>
                </p>
              </div>
            </b-card>
            <b-card class="iq-border-radius-10 mb-3">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-money-bill-alt"></i>
                  الضريبة
                </h5>
              </template>
              <div class="m-0 p-0">
                <p v-for="(i,key) in allTaxes" :key="key">
                  <b-form-checkbox class="custom-checkbox-color-checked" plain v-model="product.tax_id" color="warning"
                                   name="color" :value="i.id" >
                    <span class="text-black font-size-16 mx-4"> {{i.title}} - % {{i.percentage}}  </span>
                  </b-form-checkbox>
                </p>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import productsService from '../services/products'
import collectionsService from '../collections/services/collections'
import categoriesService from '../categories/services/categories'
export default {
  components: { },
  mounted () {
    this.url_data = this.$route.params.id
    core.index()
  },
  data () {
    return {
      product: {
        name: '',
        content: '',
        description: '',
        featured_image: '',
        status: 'pending',
        sku: '',
        quantity: '',
        price: '',
        sale_price: 0,
        discounts: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        images: [],
        product_collections: [],
        product_categories: [],
        tax_id: '',
        tags: [],
        related_products: [],
        faqs: []
      },
      faq: { question: '', answer: '' },
      showFaqInput: false,
      selectedProduct: [],
      allProducts: [],
      loadingLogo: 0,
      removeLoadingUi: false,
      loadingGallery: 0,
      allStores: [],
      loadingSubmit: false,
      productItem: {},
      productUpdate: {},
      routeName: this.$route.name,
      allCollections: [],
      allCategories: [],
      allTaxes: [],
      allStatus: ['pending', 'published']
    }
  },
  methods: {
    addFaq () {
      this.product.faqs.push(this.faq)
      this.$refs.faqs.reset()
      this.faq = { question: '', answer: '' }
    },
    removeFaq (key) {
      this.product.faqs.splice(key, 1)
    },
    getAllMerchantProducts () {
      productsService.getAllMerchantProducts().then(res => {
        this.allProducts = res.data.data
      })
    },
    saveMasterImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'products')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingLogo = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.product.featured_image = res.data.url
        this.loadingLogo = false
        this.showSuccessUploadFile()
      })
    },
    saveGalleryImage (data) {
      this.removeLoadingUi = false
      const formData = new FormData()
      formData.append('file', data.image)
      formData.append('name', data.imageInfo.name)
      formData.append('app_name', 'marketplace')
      formData.append('directory', 'products')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.loadingGallery = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.product.images.push(res.data.url)
        this.showSuccessUploadFile()
        this.removeLoadingUi = true
      })
    },
    addProduct (productData) {
      this.product.related_products.push({ id: productData.id })
    },
    removeProduct (productData) {
      var position = this.product.related_products.findIndex(product => product === productData.id)
      this.product.related_products.splice(position, 1)
    },
    removeGalleryImage (data1) {
      // eslint-disable-next-line eqeqeq
      const indexOfImage = this.product.images.findIndex(data => data == data1)
      this.product.images.splice(indexOfImage, 1)
    },
    getProduct () {
      productsService.getProduct(this.$route.params.id).then(res => {
        this.product.name = res.data.name
        this.product.content = res.data.content
        this.product.description = res.data.description
        this.product.featured_image = res.data.featured_image
        this.product.images = res.data.product_images
        this.product.tags = res.data.product_tags.map(item => item.name)
        this.product.tax_id = res.data.tax_id
        this.product.product_categories = res.data.product_categories.map(item => item.id)
        this.product.product_collections = res.data.product_collections.map(item => item.id)
        this.product.related_products = res.data.related_products.map(item => item.id)
        this.product.faqs = res.data.faqs ? res.data.faqs : []
        this.product.sku = res.data.sku
        this.product.quantity = res.data.quantity
        this.product.price = res.data.price
        this.product.discounts = res.data.discounts
        this.product.sale_price = res.data.sale_price
        this.product.length = res.data.length
        this.product.width = res.data.width
        this.product.height = res.data.height
        this.product.weight = res.data.weight
        this.product.status = res.data.status
      })
    },
    editProduct () {
      this.loadingSubmit = true
      productsService.editProduct(this.$route.params.id, this.product).then(() => {
        core.showSnackbar('success', 'تم تعديل المنتج بنجاح')
        this.$router.push({ name: 'products' })
      }).finally(() => {
        this.loadingSubmit = false
      })
    },
    getAllCategories () {
      categoriesService.getAllCategories().then((res) => {
        this.allCategories = res.data.data
      })
    },
    getAllCollections () {
      collectionsService.getAllCollections().then((res) => {
        this.allCollections = res.data.data
      })
    },
    getAllTaxes () {
      categoriesService.getAllTaxes().then((res) => {
        this.allTaxes = res.data.data
      })
    },
    onSubmit () {
      // eslint-disable-next-line eqeqeq
      if (this.$route.name == 'editProduct') {
        this.editProduct()
      } else {
        this.loadingSubmit = true
        productsService.addNewProduct(this.product).then(() => {
          core.showSnackbar('success', 'تم إضافه المنتج بنجاح')
          this.$router.push({ name: 'products' })
          this.loadingSubmit = false
        }).finally(() => {
          this.loadingSubmit = false
        })
      }
    }
  },
  created () {
    this.getAllCategories()
    this.getAllCollections()
    this.getAllTaxes()
    this.getAllMerchantProducts()
    // this.getAllStores()
    if (this.$route.params.id) {
      this.getProduct()
    }
    // this.getProduct()
  }
}
</script>
<style>
.view-main-img{
  width: 120px;
  height: 120px;
}
.image_slider_background {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
